//engines_browser.js

$(function() {

    $(".application-reload-page").on( 'click', (event) =>  {
        event.preventDefault();
        const path = window.location.pathname;
        console.log("RELOAD LAUNCHED");
        setTimeout(() => {
            browse_to_engine(path);
        }, 500 )

    });


    $("#lihtconfigs-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/configs/configs/index';
        if ( $("#lihtconfigs-sidebar-menu").hasClass('menu-open') ) {
            console.log("lihtusers is open");
        } else {
            console.log("lihtusers is closed");
            browse_to_engine(url);
        }
    });

    $("#lihtusers-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/lihtusers/users/index';
        if ( $("#lihtusers-sidebar-menu").hasClass('menu-open') ) {
            console.log("lihtusers is open");
        } else {
            console.log("lihtusers is closed");
            browse_to_engine(url);
        }
    });

    $("#cheeses-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/cheeses/cheeses/index';
        if ( $("#cheeses-sidebar-menu").hasClass('menu-open') ) {
            console.log("cheeses is open");
        } else {
            console.log("cheeses is closed");
            browse_to_engine(url);
        }
    });

    $("#customers-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/customers/customers/index';
        if ( $("#customers-sidebar-menu").hasClass('menu-open') ) {
            console.log("customers is open");
        } else {
            console.log("customers is closed");
            browse_to_engine(url);
        }
    });


});

window.browse_to_engine = (url) => {

    const auth_token = localStorage.getItem('auth_token');
    const authenticity_token = $("#authenticity_token").val();

    let str = '';
    str += '<form action="' + url + '" method="post" id="engines-browser-redirect-form">';
    str += '<input type="hidden" name="auth_token" value="' + auth_token + '">';
    str += '<input type="hidden" name="authenticity_token" value="' + authenticity_token + '">';
    str += '</form>';

    $("#engines-browser-redirect-wrapper").html(str);

    $("#engines-browser-redirect-form").trigger("submit");

}