// Cheeses.js

import { event } from 'jquery';
import fetch from '../../fetch_with_timeout';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es'
import QrScanner from 'qr-scanner'; // From https://github.com/nimiq/qr-scanner

$(function() {
    const auth_token = localStorage.getItem('auth_token');
    const locale = $("#locale").val();
    const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": 0}
    
    flatpickr( "#batch-date", {
        enableTime: false,
        dateFormat: "d/m/Y",
        "locale": Spanish,
        onClose: function(){
            $.getJSON( "/es/cheeses/api/v1/get_last_batch_uuid", function( data ) {
                console.log(data);
                if ( data.status == 401 ) {
                    toast_msg(data.error_msg, 'warning');
                } else {
                    $("#batch-uuid").val(data.next_uuid);
                    $('.batch-uuid-wrapper').fadeIn(200);
                }
            });
        }
    });

    $("#cheeses-list-btn").on( 'click', (event) => {
        event.preventDefault();
        cheeses_toggle_list_new(0);
        $('#cheeses-list-wrapper').fadeIn(200);
    });

    $("#cheeses-search-btn").on( 'click', (event) => {
        event.preventDefault();
        cheeses_toggle_list_new(1);
        $('#cheeses-readqr-wrapper').fadeIn(200);
    });

    $("#cheeses-new-btn").on( 'click', (event) => {
        event.preventDefault();
        cheeses_toggle_list_new(2);
        $('#cheeses-new-wrapper').fadeIn(200);
    });

    $("#sm-quantity").on("input change", () => {
        const sm_val = $("#sm-quantity").val();
        $("#sm-val").text(sm_val);
    });

    $("#md-quantity").on("input change", () => {
        const md_val = $("#md-quantity").val();
        $("#md-val").text(md_val);
    });

    $("#lg-quantity").on("input change", () => {
        const lg_val = $("#lg-quantity").val();
        $("#lg-val").text(lg_val);
    });

    $("#dis-sm-quantity").on("input change", () => {
        const current_sm_val = parseInt($("#discount-sm-total").val());
        const dis_sm_val = parseInt($("#dis-sm-quantity").val());
        $("#dis-sm-val").text(dis_sm_val);
        $("#discount-sm-total-text").text((current_sm_val-dis_sm_val).toString());
    });

    $("#dis-md-quantity").on("input change", () => {
        const current_md_val = parseInt($("#discount-md-total").val());
        const dis_md_val = $("#dis-md-quantity").val();
        $("#dis-md-val").text(dis_md_val);
        $("#discount-md-total-text").text((current_md_val-dis_md_val).toString());
    });

    $("#dis-lg-quantity").on("input change", () => {
        const current_lg_val = parseInt($("#discount-lg-total").val());
        const dis_lg_val = $("#dis-lg-quantity").val();
        $("#dis-lg-val").text(dis_lg_val);
        $("#discount-lg-total-text").text((current_lg_val-dis_lg_val).toString());
    });

    $("#cheeses-btn-create-new-batch").on( 'click', (event) => {
        event.preventDefault();
        $('#cheeses-new-batch-form').validate({
            rules: {
                batch_date: {
                    required: true
                },
                batch_uuid: {
                    required: true,
                    remote: "/es/cheeses/api/v1/check_batch_uuid"
                },
                sm_quantity: {
                    required: true
                },
                md_quantity: {
                    required: true
                },
                lg_quantity: {
                    required: true
                }                
            },
            messages: {
                // batch_uuid: {
                //      required: "Escriba un valor para el lote",
                //      remote: "El número de lote ya existe. Ponga otro."
                // },
                // password: {
                //     required: "Please provide a password",
                //     minlength: "Your password must be at least 5 characters long"
                // },
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
                error.addClass('invalid-feedback');
                element.closest('.form-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            }
        });

        values["batch_date"] = $("#batch-date").val();
        values["batch_uuid"] = $("#batch-uuid").val();
        values["sm_quantity"] = $("#sm-quantity").val();
        values["md_quantity"] = $("#md-quantity").val();
        values["lg_quantity"] = $("#lg-quantity").val();

        if ( $("#cheeses-new-batch-form").valid() ) {
            cheeses_create_new_batch_action(auth_token, values);
            console.log("Form valido");
        } else {
            console.log("NO valido");
        }
        
    });

    $("#cheeses-btn-discounts-batch").on( 'click', (event) => {
        event.preventDefault();
        values["batch_id"] = $("#dis-batch-id").val();
        values["dis_sm_quantity"] = $("#dis-sm-quantity").val();
        values["dis_md_quantity"] = $("#dis-md-quantity").val();
        values["dis_lg_quantity"] = $("#dis-lg-quantity").val();
        values["customer_id"] = $("#selector-customer-id option:selected").val();
        cheeses_create_discounts_action(auth_token, values);
    });

    $("#cheeses-download-info").on( 'click', (event) => {
        event.preventDefault();
    });



    $("#cheeses-btn-batch-qrcode").on( 'click', () => {
        const batch_qr_code = $("#batch-qrcode").val()
        $.getJSON( "/es/cheeses/api/v1/check_batch_uuid_qr?batch_uuid="+batch_qr_code, function( data ) {
            if (data.status == 404){
                scanner.stop();
                Swal.fire({
                    title: 'Info',
                    icon: 'error',
                    html: '<h2>'+batch_qr_code+'</h2><p>Codigo no existente en el sistema</p>',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Continuar'
                }).then((confirm) => {
                    if(confirm.isConfirmed){
                        scanner.start();
                    }
                });
                        
            } else {
                scanner.stop();
                values["batch_id"] = data.batch_id
                if (data.difference <= 60) {
                    Swal.fire(
                        '¡¡Ojo!!',
                        'Este lote solo lleva '+data.difference+' días de curación',
                        'warning'
                    )
                }
                $('#cheeses-readqr-wrapper').fadeOut(300);
                cheeses_modify_quantities_batch_action(auth_token, values);
            }
        });
    });


    //QrScanner.WORKER_PATH = 'qr-scanner/qr-scanner-worker.min.js';
    //QrScannerLib.WORKER_PATH = QrScannerWorkerPath;

    const video = document.getElementById('qr-video');
    const camList = document.getElementById('cam-list');

    // ####### Web Cam Scanning #######
    const scanner = new QrScanner(video, result => {
        console.log(result);
            if (result != undefined){
                scanner.stop();
                $.getJSON( "/es/cheeses/api/v1/check_batch_uuid_qr?batch_uuid="+result, function( data ) {
                    if (data.status == 404){
                        scanner.stop();
                        Swal.fire({
                            title: 'Info',
                            icon: 'error',
                            html: '<h2>'+result+'</h2><p>Codigo no existente en el sistema</p>',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Continuar'
                        }).then((confirm) => {
                            if(confirm.isConfirmed){
                                scanner.start();
                            }
                        });
                        
                    } else {
                        scanner.stop();
                        values["batch_id"] = data.batch_id
                        if (data.difference <= 60) {
                            Swal.fire(
                                '¡¡Ojo!!',
                                'Este lote solo lleva '+data.difference+' días de curación',
                                'warning'
                            )
                        }
                        $('#cheeses-readqr-wrapper').fadeOut(300);
                        cheeses_modify_quantities_batch_action(auth_token, values);

                    }
                });
            }
            
        },
        error => {
            //console.error(error);
        }
    );



    const updateFlashAvailability = () => {
        scanner.hasFlash().then(hasFlash => {
            if(hasFlash){
                $("#yes-flash").show();
            } else {
                $("#no-flash").show();
            }
        });
    };
    $("#yes-flash").on( 'click', () => {
        scanner.toggleFlash()
        .then(() => {
            if( scanner.isFlashOn() ){
                $("#yes-flash").css('color', 'green');
            } else {
                $("#yes-flash").css('color', 'gray');
            }
        })
        .catch((error) => {
            console.log(error);
        })
    })

    scanner.start().then(() => {
        updateFlashAvailability();
        // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
        // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
        // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
        // start the scanner earlier.
        QrScanner.listCameras(true).then(cameras => cameras.forEach(camera => {
            const option = document.createElement('option');
            option.value = camera.id;
            option.text = camera.label;
            camList.add(option);
        }));
    });


    QrScanner.hasCamera()
    .then((hasCamera) => {
        if(hasCamera){
            $("#check-if-has-camera").html('<i class="fas fa-check-circle fa-2x" style="color: green;"></i>');
        } else {
            $("#check-if-has-camera").html('<i class="fas fa-times-circle fa-2x" style="color: red;"></i>');
        }
    })
    .catch((error) => {
        console.log(error);
    })

    // for debugging
    window.scanner = scanner;

    document.getElementById('show-scan-region').addEventListener('click', (e) => {
    //window.addEventListener('load', (e) => {
        const input = e.target;
        const label = input.parentNode;
        label.parentNode.insertBefore(scanner.$canvas, label.nextSibling);
        scanner.$canvas.style.display = input.checked ? 'block' : 'none';

        $("#qr-video").toggle();
    });

    camList.addEventListener('change', event => {
        scanner.setCamera(event.target.value).then(updateFlashAvailability);
    });

});

window.reset_cheeses_batch_form = () => {
    $("#batch-date").val('');
    $("#batch-uuid").val('');
    $("#sm-quantity").val('0');
    $("#md-quantity").val('0');
    $("#lg-quantity").val('0');
    $("#sm-val").text('0');
    $("#md-val").text('0');
    $("#lg-val").text('0');
    $('.batch-uuid-wrapper').hide();
}

window.cheeses_toggle_list_new = (status) => {
    $('#cheeses-list-wrapper').hide();
    $('#cheeses-readqr-wrapper').hide();
    $('#cheeses-new-wrapper').hide();
    $('#cheeses-add-discounts-wrapper').hide();

    $("#cheeses-list-btn").removeClass('btn-primary').addClass('btn-secondary');
    $("#cheeses-search-btn").removeClass('btn-primary').addClass('btn-secondary');
    $("#cheeses-new-btn").removeClass('btn-primary').addClass('btn-secondary');

    if ( status == 0 ) {
        $("#cheeses-list-btn").removeClass('btn-secondary').addClass('btn-primary');
    } else if ( status == 1 ) {
        $("#cheeses-search-btn").removeClass('btn-secondary').addClass('btn-primary');
        $("#batch-qrcode").val('');
        scanner.start();
    } else {
        $("#cheeses-new-btn").removeClass('btn-secondary').addClass('btn-primary');
    }

}

window.cheeses_create_new_batch_action = (auth_token, values) => {
    console.log(values);
    spin_in();
    fetch('/'+values.locale+'/cheeses/api/v1/cheeses_create_new_batch',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else if ( data.status == 422 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            const batch = data.batch;
            let html = '';
            html += '<tr id="batch-id-'+batch.id+'">';

            html += '<td>';
            html += '<div class="btn-group">';
            html += '<a href="#" class="fas fa-ellipsis-v" data-bs-toggle="dropdown" aria-expanded="false">';
            html += '<span class="visually-hidden">Toggle Dropdown</span>';
            html += '</a>';
            html += '<ul class="dropdown-menu">';
            html += '<li><a class="dropdown-item" href="/qr/'+batch.uuid+'.pdf" target="_blank">Ver QR en PDF</a></li>';
            html += '<li><a class="dropdown-item" href="javascript:cheeses_delete_batch_swal_alert(\''+batch.id+'\', \''+batch.uuid+'\');">Eliminar</a></li>';
            html += '</ul>';
            html += '</div>';
            html += '</td>';
            

            html += '<td>'+batch.uuid+'</td>';
            html += '<td>'+data.batch_date+'</td>';
            html += '<td>'+batch.sm+'</td>';
            html += '<td>'+batch.md+'</td>';
            html += '<td>'+batch.lg+'</td>';
            html += '</tr>';

            $("#cheeses-batch-list tbody").prepend(html);

            // reset new form
            reset_cheeses_batch_form();

            cheeses_toggle_list_new(0);
            $('#cheeses-new-wrapper').fadeOut(200);
            setTimeout(() => {
                $('#cheeses-list-wrapper').fadeIn(200);
            },200 );

        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.cheeses_modify_quantities_batch_action = (auth_token, values) => {
    //console.log(values);
    spin_in();
    fetch('/'+values.locale+'/cheeses/api/v1/cheeses_modify_quantities_batch',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        

        if ( data.status == 401 ) {
            spin_out();
            toast_msg(data.error_msg, 'warning');
        } else {
            const batch = data.batch;

            // Batch uuid and date
            $("#dis-batch-id").val(batch.id);
            $("#discount-batch-uuid").text(batch.uuid);
            $("#discount-batch-date").text(data.batch_date);

            // Batch original quantities
            $("#discount-sm-ori").text(batch.sm);
            $("#discount-md-ori").text(batch.md);
            $("#discount-lg-ori").text(batch.lg);

            // bacth current quantity hidden
            $("#discount-sm-total").val(data.rest_sm);
            $("#discount-md-total").val(data.rest_md);
            $("#discount-lg-total").val(data.rest_lg);
            
            // bacth current quantity text
            $("#discount-sm-total-text").text(data.rest_sm);
            $("#discount-md-total-text").text(data.rest_md);
            $("#discount-lg-total-text").text(data.rest_lg);

            $("#dis-sm-quantity").attr('max', data.rest_sm);
            $("#dis-md-quantity").attr('max', data.rest_md);
            $("#dis-lg-quantity").attr('max', data.rest_lg);


            $("#cheeses-add-discounts-wrapper").fadeIn(200);
            spin_out();

        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.cheeses_create_discounts_action = (auth_token, values) => {
    //console.log(values);
    spin_in();
    fetch('/'+values.locale+'/cheeses/api/v1/cheeses_create_discounts',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            const url = '/es/cheeses/cheeses/index';
            browse_to_engine(url);
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.cheeses_delete_batch_swal_alert = (batch_id, batch_uuid) => {
    Swal.fire({
        title: '¿Está seguro?',
        text: "Va a eliminar el lote "+batch_uuid+ ", y con él se eliminarán las salidas de quesos asociadas al lote",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Eliminar'
    })
    .then((result) => {
        if (result.isConfirmed) {
            const auth_token = localStorage.getItem('auth_token');
            const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "batch_id": batch_id}
            spin_in();
            fetch('/'+values.locale+'/cheeses/api/v1/cheeses_delete_batch',
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${auth_token}`,
                    },
                    body: JSON.stringify(values),
                },
                10000
            )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                spin_out();
                if(data.status != 200) {
                    Swal.fire(
                        'Error',
                        'Se ha producido un error al intentar eliminar el lote',
                        'warning'
                    )
                } else {
                    $("#batch-id-"+batch_id).remove();
                }
            })
            .catch((error) => {
                spin_out();
                error_msg(error);
            })
        }
    })

}

window.cheeses_download_report = (year) => {

    const auth_token = localStorage.getItem('auth_token');
    const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "year": year}

    const url = "/es/cheeses/api/v1/cheeses_download_report";

    let xhr = new XMLHttpRequest();
    //set the request type to post and the destination url to '/convert'
    xhr.open('POST', url);
    //set headers
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer "+auth_token);
    //set the reponse type to blob since that's what we're expecting back
    xhr.responseType = 'blob';
    let postData = JSON.stringify(values);
    xhr.send(postData);

    xhr.onload = function(e) {
        if (this.status == 200) {
            // let contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
            // console.log(contentDispo);
            // let fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            //let fileName = xhr.getResponseHeader('Content-Disposition').split("filename=")[1];
            let fileName = values.year.toString() + "_informe.xlsx"
            console.log(fileName);
            // Create a new Blob object using the response data of the onload object
            var blob = new Blob([this.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            //Create a DOMString representing the blob and point the link element towards it
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            //a.download = 'myFile.pdf';
            a.download = fileName;
            //programatically click the link to trigger the download
            a.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
        }else{
            //deal with your error state here
            console.log("ERROR en el else")
        }
    };
}

window.cheeses_open_sales_pdf = (batch_id) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "batch_id": batch_id}
    const url = "/es/cheeses/api/v1/cheeses_open_sales_pdf";

    let xhr = new XMLHttpRequest();
    //set the request type to post and the destination url to '/convert'
    xhr.open('POST', url);
    //set headers
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer "+auth_token);
    //set the reponse type to blob since that's what we're expecting back
    xhr.responseType = 'blob';
    let postData = JSON.stringify(values);
    xhr.send(postData);

    xhr.onload = function(e) {
        if (this.status == 200) {
            // let contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
            // console.log(contentDispo);
            // let fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            //let fileName = xhr.getResponseHeader('Content-Disposition').split("filename=")[1];
            let fileName = "batch.pdf"
            console.log(fileName);
            // Create a new Blob object using the response data of the onload object
            var blob = new Blob([this.response], {type: 'application/pdf'});
            //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            //Create a DOMString representing the blob and point the link element towards it
            let url = window.URL.createObjectURL(blob);
            console.log(url);
            window.open(url);

            //a.href = url;
            //a.download = 'myFile.pdf';
            //a.download = fileName;
            //programatically click the link to trigger the download
            //a.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
        }else{
            //deal with your error state here
            console.log("ERROR en el else")
        }
    };
}