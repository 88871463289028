// customers.js

import { event } from 'jquery';
import fetch from '../../fetch_with_timeout';

$(function() {
    const auth_token = localStorage.getItem('auth_token');
    const locale = $("#locale").val();
    const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": 0}

    $("#new-customer-btn").on( 'click', (event) => {
        event.preventDefault();
        values["name"] = $("#new-customer").val();
        customers_create_new_customer_action(auth_token, values);
    });
    
});


window.customers_create_new_customer_action = (auth_token, values) => {
    //console.log(values);
    spin_in();
    fetch('/'+values.locale+'/customers/api/v1/customers_create_new_customer',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            let html = '';
            html += '<tr>';
            html +=     '<td>';
            html +=         '<div class="form-group form-group-no-bottom">';
            html +=             '<input type="text" class="form-control form-control-md" name="customer_id" id="customer-name'+data.customer.id+'" placeholder="Nombre cliente..." value="'+data.customer.name+'">';
            html +=         '</div>';
            html +=     '</td>';
            html +=     '<td><a href="#" class="fas fa-arrow-circle-right fa-2x edit-customer-btn" cus-id="'+data.customer.id+'"></a></td>';
            html += '</tr>';

            $("#new-customer").val('');
            $("#customers-customers-list tbody").prepend(html);

        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.customers_edit_customer_action = (id) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "id": id, "name": $("#customer-name-"+id).val() }
    spin_in();
    fetch('/'+values.locale+'/customers/api/v1/customers_edit_customer',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            $("#customer-name-"+id).val('');

            setTimeout(() =>{
                $("#customer-name-"+id).val(data.customer.name);
            },300);
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}



